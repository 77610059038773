body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* reset */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
q,
small,
strike,
strong,
sub,
sup,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  border: 0;
  margin: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

ol,
ul,
li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  padding: 0;
  border: 0;
  margin: 0;
  font: inherit;
  font-size: 100%;
  letter-spacing: inherit;
  vertical-align: baseline;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
button,
textarea,
select {
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  margin: 0;
  appearance: none;
  background: none;
  border-radius: 0;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  outline: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

textarea {
  overflow: auto;
  resize: none;
}

select::-ms-expand {
  display: none;
}

input::-ms-clear {
  visibility: hidden;
}

input::-ms-reveal {
  visibility: hidden;
}

::-webkit-search-cancel-button {
  appearance: none;
}

button {
  overflow: visible;
}

* {
  box-sizing: border-box;
}

.container {
  height: 100vh;
  width: 100%;
  min-height: 764px;
}

.content-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  padding: 0 1rem;
}

@media screen and (min-width: 640px) {}

@media screen and (min-width: 1024px) {
  .content-container {
    padding: 0;
  }
}

@media screen and (min-width: 1200px) {
  .content-container {
    width: 1170px;
  }
}