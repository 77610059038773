/* @font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?srf3rx");
  src: url("../fonts/icomoon/icomoon.eot?srf3rx#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?srf3rx") format("truetype"), url("../fonts/icomoon/icomoon.woff?srf3rx") format("woff"), url("../fonts/icomoon/icomoon.svg?srf3rx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
} */

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Space Mono", Arial, serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: #4d4d4d;
  background: #fff;
}