.my-resume {
  margin-top: 15%;
  text-align: center;

  h2 {
    font-size: 50px;
  }
}

.experience {
  display: flex;
  flex-direction: column;
  .experience-part {
    width: max-content;
    padding: 7px 15px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: bold;
    background: #e6e6e6;
    border-radius: 4px;
  }

  .vertical-timeline-element {
    .vertical-timeline-element-content {
      padding: 0;

      p {
        margin: 0;
      }
    }
  }
  .vertical-timeline-element-icon {
    margin-top: 1.5em;
    border: none;
    box-shadow: none;
  }

  .title {
    font-size: 24px;
  }

  .company-name {
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    color: #888686;
  }

  .tech-skills {
    span {
      font-weight: 700;
      font-size: large;
    }
  }

  .time {
    font-size: 14px;
    color: #bfbfbf;
  }
}

.timeline-icon {
  width: 40px;
  height: 40px;
  background: white;
  border: 0.5px solid gray;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
  }
}

@media screen and (min-width: 640px) {
}

@media screen and (min-width: 1024px) {
  .experience {
    align-items: center;
  }

  .timeline-icon {
    width: 60px;
    height: 60px;
  }

  .vertical-timeline-element {
    &:nth-child(2n + 1) {
      text-align: right;
    }
    &:nth-child(2n) {
      text-align: left;
    }
  }
}

@media screen and (min-width: 1200px) {
  .content-container {
    width: 1170px;
  }
}
