.about-me {
  margin-top: 15%;
  text-align: center;

  h2 {
    font-size: 50px;
  }
}

.content {
  .detail-info {
    .info-row {
      margin-bottom: 1rem;

      .info-title {
        font-size: 20px;
        font-weight: bold;
      }

      .info-content {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
@media screen and (min-width: 640px) {
}

@media screen and (min-width: 768px) {
}
@media screen and (min-width: 1024px) {
  .content {
    display: flex;
    margin-top: 5rem;

    .detail-info {
      width: 40%;

      .info-row {
        display: flex;
        align-items: baseline;

        .info-title {
          width: 30%;
        }

        .info-content {
          width: 70%;
        }
      }
    }

    .about {
      margin-left: 2rem;
      width: 60%;

      .hi-there {
        text-align: center;
        margin-top: -2rem;
        margin-bottom: 1rem;
      }
    }
  }
}
@media screen and (min-width: 1200) {
}
