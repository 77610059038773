.container {
  position: relative;
  max-width: 100%;

  .background {
    position: absolute;
    z-index: 1;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 764px;
  }

  .overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(112 107 102 / 53%);
  }

  .info {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    color: white;
    top: 20%;
    .avatar {
      width: 200px;
      height: 200px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .name {
      font-family: 'Kaushan Script', cursive;
      font-size: 40px;
      line-height: 1.3;
      font-weight: 300;
      transform: rotate(-5deg);
    }

    .social {
      display: flex;
      gap: 15px;

      .social-icon {
        &:hover {
          img {
            transform: scale(1.2);
            transition: 0.2s;
          }
        }
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}


@media screen and (min-width: 1024px) {
  .name {
    font-size: 50px;
  }
}
